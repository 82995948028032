body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Inter",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f7fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo.is-animation span {
  display: inline-block;
  animation: wave-text 1s ease-in-out infinite;
}
.logo.is-animation span:nth-of-type(1) {
  animation-delay: 0s;
}
.logo.is-animation span:nth-of-type(2) {
  animation-delay: 0.1s;
}
.logo.is-animation span:nth-of-type(3) {
  animation-delay: 0.2s;
}

@keyframes wave-text {
  0% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.2em);
  }
  100% {
    transform: translateY(0em);
  }
}

.jGbqEh {
  display: flex;
  width: 250px;
  flex-direction: row;
  border-radius: 50px 40px 40px 50px;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(60, 66, 80);
  margin: 15px 15px;
}

.joGUys {
  height: 54px;
  width: 54px;
  padding: 0px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition: all 0.5s ease 0s;
  border: 6px solid rgb(60, 66, 80);
  pointer-events: auto;
}
.joGUys:hover {
  border: 4px solid rgb(255, 84, 84);
}
.kZIiFT {
  height: 20px;
  width: 20px;
  border-radius: 20%;
  background-color: rgb(255, 84, 84);
  border-color: rgb(255, 84, 84);
  transition: all 0.5s ease 0s;
}
.dqrcjX {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

@media (min-width: 768px) {
  .gHlgWL {
    margin: 0px 20px;
  }
}
.gHlgWL {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  right: 30px;
  color: rgb(255, 255, 255);
  margin: 0px 12px;
  width: 50px;
}
.eqplYX {
  display: flex;
  flex-direction: row;
  -webkit-box-align: baseline;
  align-items: baseline;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.ddcJli {
  border: none;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 100%;
  background-color: rgb(109, 114, 124);
  transition: hover 0.3s ease-in 0s;
  cursor: auto;
  pointer-events: all;
  width: 33px;
  height: 33px;
}

.dEUQBF {
  stroke: rgb(255, 255, 255);
  height: 16px;
  width: 12px;
  color: rgb(255, 255, 255);
}

.deviceDropDown {
  height: 40px;
  border-radius: 8px;
  border: 2px #3c4250 solid;
  background-color: inherit;
  padding: 5px;
}

@-webkit-keyframes screenAnimation {
  0% {
    opacity: 100%;
  }

  15% {
    opacity: 45%;
  }

  100% {
    opacity: 25%;
  }
}

@-moz-keyframes screenAnimation {
  0% {
    opacity: 100%;
  }

  15% {
    opacity: 45%;
  }

  100% {
    opacity: 25%;
  }
}

@-o-keyframes screenAnimation {
  0% {
    opacity: 100%;
  }

  15% {
    opacity: 45%;
  }

  100% {
    opacity: 25%;
  }
}

@keyframes screenAnimation {
  0% {
    opacity: 100%;
  }

  15% {
    opacity: 45%;
  }

  100% {
    opacity: 25%;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #7d7aff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7d7aff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
